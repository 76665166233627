import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Button,
  styled,
} from '@material-ui/core'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useAuth0 } from '@auth0/auth0-react'

import { useEffect } from 'react'
import { encryptData } from 'manage-tritag/utils/crypto'

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1424)]: {
        fontSize: '0.85rem',
      },
    }
  }}
  &:hover {
    border: 2px solid white;
  }
  border: 2px solid #fff;
  color: #fff;
  margin-right: 1.5rem;
  text-transform: none;
  font-size: 0.9rem;
`
export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1424)]: {
        fontSize: '1.1rem',
      },
    }
  }}
  &:hover {
    border-bottom: 2px solid white;
  }
  padding-bottom: 4px;
`
const StyledGatsbyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledLink = styled('a')`
  text-decoration: none;
  color: inherit;
`

interface NavbarProps {
  bg?: string
  path?: any
  absolute?: boolean
}

export default function Navbar({ bg, path, absolute }: NavbarProps) {
  const { user, logout, getAccessTokenSilently, loginWithPopup } = useAuth0()

  useEffect(() => {
    fetchUser()
  }, [user])

  const fetchUser = async () => {
    if (user) {
      const token = await getAccessTokenSilently()
      localStorage.setItem('mahiwagangTanzan', encryptData(token))
    }
  }

  const isActive = (route: string) => {
    if (path === route) {
      return '2px solid white'
    }
    return 'none'
  }

  return (
    <Box
      sx={{
        position: absolute ? 'absolute' : 'unset',
        width: '100%',
        zIndex: '99',
      }}
    >
      <AppBar position="static" sx={{ background: bg, boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <StyledGatsbyLink to="/">
              <StaticImage
                src="../../images/logo.png"
                alt="logo"
                height={45}
                placeholder="blurred"
                style={{ flexGrow: 1 }}
              />
            </StyledGatsbyLink>
          </Typography>
          <StyledGatsbyLink to="/about">
            <StyledTypography
              variant="h6"
              sx={{ mr: 4, borderBottom: isActive('/about') }}
            >
              About
            </StyledTypography>
          </StyledGatsbyLink>
          <StyledGatsbyLink to="/who-we-are">
            <StyledTypography
              variant="h6"
              sx={{ mr: 4, borderBottom: isActive('/who-we-are') }}
            >
              Who we are
            </StyledTypography>
          </StyledGatsbyLink>
          <StyledGatsbyLink to="/run-a-competition">
            <StyledTypography
              variant="h6"
              sx={{ mr: 4, borderBottom: isActive('/run-a-competition') }}
            >
              Run a competition
            </StyledTypography>
          </StyledGatsbyLink>
          <StyledGatsbyLink to="/kids-korner">
            <StyledTypography
              variant="h6"
              sx={{ mr: 4, borderBottom: isActive('/kids-korner') }}
            >
              Kids Korner
            </StyledTypography>
          </StyledGatsbyLink>
          <StyledLink
            href="https://tritagrugby.store"
            target="_blank"
            rel="noreferrer"
          >
            <StyledTypography variant="h6" sx={{ mr: 4, borderBottom: 'none' }}>
              Merch
            </StyledTypography>
          </StyledLink>
          <StyledGatsbyLink to="/register">
            <StyledTypography
              variant="h6"
              sx={{ mr: 4, borderBottom: isActive('/register') }}
            >
              Register
            </StyledTypography>
          </StyledGatsbyLink>
          <Typography variant="h6" component="div" sx={{ ml: 1, mr: 5 }}>
            {user ? (
              <StyledButton variant="outlined" onClick={() => logout()}>
                Log out
              </StyledButton>
            ) : (
              <StyledButton variant="outlined" onClick={() => loginWithPopup()}>
                Sign in
              </StyledButton>
            )}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Navbar.defaultProps = {
  bg: 'none',
  absolute: false,
  path: '/',
}
