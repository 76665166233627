import {
  AppBar,
  Box,
  Toolbar,
  Button,
  styled,
  Drawer,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'gatsby'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { useEffect, useState } from 'react'
import { encryptData } from 'manage-tritag/utils/crypto'

export const StyledButton = styled(Button)`
  border: 2px solid #fff;
  color: #fff;
  text-transform: none;
  font-size: 0.9rem;
  margin-left: 1.8rem;
  margin-bottom: 2rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 0.9rem;
`
const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: #008174 !important;
    color: #fff;
    border: none;
    width: 100%;
    height: 100%;
  }
`

const StyledBrand = styled(Box)`
  display: flex;
  justify-content: center;
`

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#008174',
  })}
`

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  marginTop: '-0.5rem',
  marginBottom: '1.3rem',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}))

const StyledGatsbyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &&:hover {
    cursor: pointer;
  }
`

const StyledLink = styled('a')`
  text-decoration: none;
  color: inherit;
  &&:hover {
    cursor: pointer;
  }
`

const BurgerMenu = styled(Box)`
  &&:hover {
    cursor: pointer;
  }
`

interface MobileNavbarProps {
  bg?: string
  path?: string
}

export default function MobileNavbar({ path, bg }: MobileNavbarProps) {
  const [open, setOpen] = useState(false)
  const { user, logout, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  useEffect(() => {
    fetchUser()
  }, [user])

  const fetchUser = async () => {
    if (user) {
      const token = await getAccessTokenSilently()
      localStorage.setItem('mahiwagangTanzan', encryptData(token))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <StyledAppBar
        sx={{
          background: path === '/' ? 'none' : bg,
          boxShadow: 'none',
          position: path === '/' ? 'absolute' : 'unset',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledGatsbyLink to="/">
            <StaticImage
              src="../../images/logo.png"
              alt="logo"
              height={38}
              placeholder="blurred"
            />
          </StyledGatsbyLink>
          <BurgerMenu
            component="div"
            style={{ display: 'flex' }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon sx={{ fontSize: '2rem ' }} />
          </BurgerMenu>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer variant="temporary" anchor="top" open={open}>
        <DrawerHeader>
          <StyledBrand component="div" my={3}>
            <StyledGatsbyLink to="/">
              <StaticImage
                src="../../images/logo.png"
                alt="logo"
                height={40}
                placeholder="blurred"
              />
            </StyledGatsbyLink>
          </StyledBrand>
          <BurgerMenu onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </BurgerMenu>
        </DrawerHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <StyledGatsbyLink to="/about">
              <ListItem button>
                <ListItemText
                  primary="About"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledGatsbyLink>
            <StyledGatsbyLink to="/who-we-are">
              <ListItem button>
                <ListItemText
                  primary="Who we are"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledGatsbyLink>
            <StyledGatsbyLink to="/run-a-competition">
              <ListItem button>
                <ListItemText
                  primary="Run a competition"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledGatsbyLink>
            <StyledGatsbyLink to="/kids-korner">
              <ListItem button>
                <ListItemText
                  primary="Kids korner"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledGatsbyLink>
            <StyledLink
              href="https://tritagrugby.store"
              target="_blank"
              rel="noreferrer"
            >
              <ListItem button>
                <ListItemText
                  primary="Merch"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledLink>
            <StyledGatsbyLink to="/register">
              <ListItem button>
                <ListItemText
                  primary="Register"
                  primaryTypographyProps={{
                    style: { fontSize: 22, marginLeft: '0.7rem' },
                  }}
                />
              </ListItem>
            </StyledGatsbyLink>
          </Box>
          <Box>
            <span>
              {user ? (
                <StyledButton variant="outlined" onClick={() => logout()}>
                  Log out
                </StyledButton>
              ) : (
                <StyledButton
                  variant="outlined"
                  onClick={() => loginWithRedirect()}
                >
                  Sign in
                </StyledButton>
              )}
            </span>
          </Box>
        </Box>
      </StyledDrawer>
    </>
  )
}

MobileNavbar.defaultProps = {
  bg: 'none',
  path: '',
}
